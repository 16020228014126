import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Button from "~/components/button"
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Unordered from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>It can be hard to tell from an ad whether the information is accurate or if the persuasion strategies are potentially misleading.</p>

              <p>One way that people can try to make sense of information is by practicing critical thinking.</p>

              <p><strong className="font-bold">Critical thinking</strong> is a skill that allows you to make logical and informed decisions to the best of your ability. It involves analyzing an issue or situation using facts, data, and evidence—and not opinions or feelings.</p>

              <p>People who are using their critical thinking skills question the information they read. They may ask questions such as these:</p>

              <Unordered>
                <li>What did they say?</li>
                <li>Who said it?</li>
                <li>Why are they telling me this?</li>
                <li>Where did they say it?</li>
                <li>When did they say it?</li>
                <li>How did they say it?</li>
                <li>Do their words support their main idea?</li>
              </Unordered>

              <p>Click on the link below to read an article that appeared in the <span className="italic">New York Times</span>. This article presents information analyzing juice cleanses in a critical way. Can you find examples of critical thinking in this article? Record your ideas in your science notebook.</p>

              <Button
                action="secondary"
                color="indigo"
                href="https://www.nytimes.com/2016/04/21/health/juice-cleanse-toxin-misconception.html?_r=0"
                icon="external"
                title="Does juice cleanse the body?"
              >
                Does juice cleanse the body?
              </Button>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students are provided a definition of <span className="italic">critical thinking</span> and some questions that are indicative of critical thinking. A misconception is that critical thinking is being critical of others or being argumentative. If students mention this, help clarify that being critical is about finding evidence to support decision making. Critical thinking is an open-minded effort to answer a question or test a hypothesis by combining good evidence with good ideas. People who exhibit good critical thinking skills generally:</p>


              <Unordered>
                <li>are open to new ideas,</li>
                <li>persist and have staying power in thinking through a problem,</li>
                <li>can appreciate others' viewpoints,</li>
                <li>are not afraid to take an unpopular stand,</li>
                <li>do not accept everything at face value, and</li>
                <li>are flexible and willing to change tactics.</li>
              </Unordered>

              <p>The article that students read in this step is intended to demonstrate some of these critical thinking skills. It is not important that students remember the specific questions that are asked, but they should recognize that two of the doctors in the story are using critical thinking skills to question information about juice cleanses. For example, near the beginning of the article, Dr. Grendell says that he has asked people what they are trying to get rid of with a cleanse. Later in the article, Dr. Saddler brings up the questions, "Why does the stomach need resting?" and "Who said that [cleansing] was beneficial?"</p>

              <p>If helpful for your students, you could project the article for the class and read part of the article aloud while students try to find examples of critical thinking. After a few examples, students can continue independently.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
